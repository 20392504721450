import React, { useState } from "react";
import { getHeader } from "@api";
import { useEventListener } from "@util/hooks";
import { Link, Image } from "@global";
import { LogoPlaceHolder, IconDown, IconLogo } from "@util/assets";

import { ColumnLogo, HeaderDesktopWrapper, ParentUl, ParentLi } from "./headerDesktop.style";

const HeaderDesktop = () => {
  const { sanityNavigation } = getHeader();
  if (sanityNavigation == null) return null;

  const { links } = sanityNavigation;
  if (links == null) return null;
  if (links.length === 0) return null;

  const [headerDesignSolid, setHeaderDesignSolid] = useState(false);

  useEventListener("scroll", () => {
    if (window.scrollY > 300) {
      return setHeaderDesignSolid(true);
    }
    return setHeaderDesignSolid(false);
  });

  console.log({ sanityNavigation });

  return (
    <HeaderDesktopWrapper designSolid={headerDesignSolid}>
      <ColumnLogo className="columnLogo">
        <Link internalLink={{ slug: { current: "/" } }}>
          <Image data={sanityNavigation?.logo} maxwidth="300px"></Image>
        </Link>
      </ColumnLogo>

      <div>
        <nav>
          <ParentUl>
            {links.map((link, i, { length }) => {
              if (link == null) return null;
              const { parentLink, childLinks, _key } = link;
              if (parentLink == null) return null;
              const [childDropDown, setChildDropDown] = useState(false);

              return (
                <ParentLi
                  key={_key}
                  isLastItem={i + 1 === length && true}
                  dropDownActive={childDropDown}
                >
                  <span className="parentLi-text" onClick={() => setChildDropDown(!childDropDown)}>
                    <Link
                      {...parentLink}
                      preventDefault={childLinks && childLinks.length ? true : false}
                    />
                    {childLinks && childLinks.length !== 0 && <IconDown />}
                  </span>

                  {childLinks && childLinks.length !== 0 && childDropDown && (
                    <ul className="this-child" onMouseLeave={() => setChildDropDown(false)}>
                      {childLinks.map(childLink => {
                        if (childLink == null) return null;
                        const { _key } = childLink;
                        return (
                          <li key={_key}>
                            <Link {...childLink} />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </ParentLi>
              );
            })}
          </ParentUl>
        </nav>
      </div>
    </HeaderDesktopWrapper>
  );
};

export default HeaderDesktop;
