import { useStaticQuery, graphql } from "gatsby";
import { Query } from "@graphql-types";

const GetHeader = () => {
  const getHeader: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        logo {
          ...sanityImageFullWidth
        }
        links {
          _key
          childLinks {
            ...sanityLink
          }
          parentLink {
            ...sanityLink
          }
        }
      }
    }
  `);

  return getHeader;
};

export default GetHeader;
